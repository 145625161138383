import React from 'react'
import SEO from '@components/seo'

import '../styles/base.scss'

const IndexPage = () => (
  <div style={{ width: '100vw' }}>
    <SEO title="Home" />
    <div
      style={{
        position: 'relative',
        overflow: 'hidden',
        height: '100vh',
        marginBottom: 16
      }}
    >
      <div style={{ position: 'absolute', left: '50%', right: '50%', width: 150 }}>
        <span>Floris List</span>
      </div>
      <div style={{ filter: 'url(#goo)' }}>
        <div
          style={{
            borderRadius: '63% 37% 54% 46% / 55% 48% 52% 45%',
            width: 200,
            height: 200,
            backgroundColor: 'blue'
          }}
        />
        <div
          style={{
            borderRadius: '63% 37% 54% 46% / 55% 48% 52% 45%',
            width: 200,
            height: 200,
            backgroundColor: 'orange'
          }}
        />
      </div>
      <svg id="starry" filter="url(#goo)" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
            applies blur to where the color collide. feColorMatrix: changes colors based on the
            matrix values.
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  
                      0 1 0 0 0  
                      0 0 1 0 0  
                      0 0 0 55 -15"
              result="goo"
            />
            {/* <feComposite in="SourceGraphic" in2="goo" operator="atop" /> */}
          </filter>
        </defs>

        {/* <g className="starry-els">
          <ellipse id="el1" ry="200" />
          <ellipse id="el2" ry="120" />
          <ellipse id="el3" ry="150" />
          <ellipse id="el3" ry="150" cy="400" />
          <ellipse id="el3" ry="150" cy="800"/>
          <ellipse id="el3" ry="150" cy="1000"/>
        </g> */}
      </svg>
    </div>
  </div>
)

export default IndexPage
